<template>
  <v-menu
    v-if="item.status"
    offset-y
    :close-on-content-click="false"
    @input="onMenuInput"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="item.status && item.eclient_request_id "
        text
        class="elevation-0"
        :class="{
          'px-0':mobile
        }"
        v-bind="attrs"
        v-on="on"
        @click="getStatusList(item)"
      >
        <v-icon v-if="item.user_id === userId">
          mdi-chevron-down
        </v-icon>
        {{ item.status }}
      </v-btn>
    </template>
    <v-simple-table
      v-if="statusList.length"
      class="pt-2"
    >
      <tbody>
        <tr
          v-for="(element, idx) in statusList"
          :key="'status-item-'+idx"
        >
          <td>{{ element.status }}</td>
          <td>{{ element.date_of }}</td>
          <td>
            <v-menu
              offset-overflow
              :nudge-left="nudgeLeft"
              :nudge-top="nudgeTop"
              :max-width="width"
              :close-on-content-click="false"
            >
              <template #activator="{ on: on2, attrs:attrs2 }">
                <v-btn
                  plain
                  v-bind="attrs2"
                  color="primary"
                  v-on="on2"
                  @click="getStatusMessage(element)"
                >
                  <v-icon>
                    mdi-comment
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                height="350px"
                class="overflow-y-auto"
              >
                <v-card-text v-html="statusMessage" />
              </v-card>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import {capitalize} from "@/helpers/text";
import moment from "moment/moment";

export default {
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    statusList:[],
    statusMessage:{},
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    }),
    userId(){
      return this.user?.user?.id
    },
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
    nudgeLeft(){
      return '500px'
    },
    nudgeTop(){
      if(this.mobile){
        return '200px'
      }
      if([3,5].includes(this.messageType)) return '100px';
      return [15].includes(this.messageType) ? '300px':'200px'
    },
    listWidth(){
      if(this.mobile) return '95vw';
      return 'auto'
    },
    width(){
      if(this.moment){
        return '100vw'
      }
      return [15].includes(this.messageType) ? '700px':'500px'
    },

  },
  methods:{
    getStatusList(item){
      const {eclient_request_id, user_id} = item
      if(user_id !== this.userId) return false
      this.$store.dispatch('journal/getStatusList', eclient_request_id).then(res => {

        this.statusList = res.data.map(item => {
          const {st_name, f_dateof, f_Id} = item
          const [first,...words] = st_name.split(" ")
          return {
            status:[capitalize(first) , ...words].join(" "),
            date_of:moment(f_dateof).format('DD.MM.YYYY HH:mm:ss'),
            id:f_Id
          }
        })

      }).catch(err => {
        this.$snackbar({text:err.response.data, color:"red", top:false, right: false});
      })
    },
    getStatusMessage(element){
      this.$store.dispatch('journal/getRawStatusMessage', element.id).then(res =>{
        this.statusMessage = res.data
      }).catch(err => {
        this.$snackbar({text:err.response.data, color:"red", top:false, right: false});
      })
    },
    onMenuInput(value){
      if(value === false){
        this.statusList = [];
        this.statusMessage = ""
      }

    },
  }
}
</script>
<style scoped>
.status-description{
    word-break: break-word;
}
</style>