import {downloadXml, getFilenameFromContentDisposition} from '@/helpers/documents'

const actions = [
  {
    icon:"mdi-tray-arrow-down",
    name:"Скачать xml",
    action:"downloadXml"
  },
]

const methods = {
  downloadXml({id}){
    this.$store.dispatch("ensuring/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
}

export {
  methods, actions
}