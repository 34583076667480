<template>
  <div>
    <div
      v-if="mobile"
      id="journal-cards"
    >
      <v-card
        v-for="(item,idx) in itemsModified"
        :key="`card-${idx}`"
        height="150"
        class="d-flex mb-3 pa-1"
        :class="getItemClass(item)"
      >
        <v-row>
          <v-col>
            <JournalUser
              :item="item"
              :user-id="userId"
            />
          </v-col>
          <v-col cols="4">
            {{ item.td_id }}
          </v-col>
          <v-col cols="3">
            {{ item.id }}
          </v-col>
          <v-col>
            <JournalActions
              :item="item"
              @perform="onPerform"
            />
          </v-col>
          <v-col class="py-0">
            {{
              item.out_number
            }}
          </v-col>
          <v-col class="py-0">
            {{
              item.regnums
            }}
          </v-col>
          <v-col cols="12">
            <JournalItemNCES :item="item" />
          </v-col>
        </v-row>
      </v-card>
      <v-data-footer

        :pagination="cardsPagination"
        :options="options"
        :items-per-page-options="[15,30,50]"
        :show-first-last-page="false"
        @update:options="onCardsOptsUpdate"
      />
    </div>
    <v-data-table
      v-else
      class="rounded-t-0 border-t-0"
      :items="itemsModified"
      :headers="headers"
      disable-sort
      :item-class="getItemClass"
      :options="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options':[15,30,50],
        'show-first-last-page':false,

      }"
      @dblclick:row="showDocument"
      @update:items-per-page="setPaginationSize"
      @update:page="setPaginationPage"
      @click:row="bufferDocument"
      @mouseover.native="bufferDocumentMouseMove"
    >
      <template #[`item.declaration_id`]="{item}">
        <span
          class="cursor-pointer primary--text"

          @click="showParentDocument(item)"
        >
          {{ item.declaration_id }}
        </span>
      </template>

      <template #[`item.declarant`]="{item}">
        <JournalUser
          :item="item"
          :user-id="userId"
        />
      </template>
      <template #[`item.id`]="{item}">
        <document-identity
          :id="item.id"
          :declaration-date="item.declaration_date"
        />
      </template>
      <template #[`item.out_number`]="{item}">
        {{ item.out_number }} <br> {{ item.remark }}
      </template>
      <template #[`item.declaration_date`]="{item}">
        <span>{{ item.declaration_date }}</span>
      </template>
      <template #[`item.regnums`]="{item}">
        <span>{{ item.reg_num_a }}</span><br>
        <span>{{ item.reg_num_b }}</span>
      </template>
      <template #[`item.status`]="{item}">
        <journal-item-status :item="item" />
      </template>
      <template #[`item.contragent`]="{item}">
        <span>{{ item.subject_name }}</span><br>
        <span>{{ item.counter_agent_name }}</span>
      </template>


      <template #[`item.actions`]="{item}">
        <JournalActions
          :item="item"
          @perform="onPerform"
        />
      </template>
    </v-data-table>
    <component
      :is="activeComponent"
      :id="selectedId"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
      :module="currentModule"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import {capitalize} from '@/helpers/text'
import {methods, actions} from './functions'
import JournalItemStatus from "@/components/ensuring/journal/journal-item-status.vue";
import JournalTableMixin from "@/mixins/journal-table.mixin";
import {getDetailPageByService} from "@/helpers/common";
import DocumentIdentity from "@/components/journal/document-identity.vue";
const MODULE = 'ensuring'

export default {
  components:{
    DocumentIdentity,
    JournalItemStatus,
  },
  mixins:[JournalTableMixin],
  data:() => ({
    currentModule:MODULE,
    actions,
    headers:[
      {
        text:"",
        value:"declarant"
      },
      {
        text:"ID",
        value:"id"
      },
      {
        text:"Документ",
        value:"declaration_id"
      },
      {
        text:"Исх.номер",
        value:"out_number"
      },
      {
        text:"Рег.номер",
        value:"regnums"
      },
      {
        text:"Статус",
        value:"status"
      },
      {
        text:"Договор",
        value:"client_contract_number"
      },
      {
        text:'Сумма',
        value: 'total'
      },
      {
        text:"",
        value:"actions"
      },

    ]
  }),
  computed:{
    ...mapGetters({
      items: "journal/getItems",
      total:"journal/totalCount",
      pagination:"journal/pagination",
      user:"auth/getUser",
    }),
    itemsModified() {
      return this.items.map((item) => {
        const {
          last_name,
          first_name,
          phone,
          email,
          id,
          declaration_id,
          declaration_type,
          declaration_date:date,
          reg_num_a,
          reg_num_b,
          subject_name,
          subject_person_name,
          counter_agent_name,
          counter_agent_person_name,
          client_email,
          client_fio,
          client_phone,
          // client_comment,
          currency_letter,sum,
          user_id,
          client_division_id,
          status = "",
          out_number,
          remark,
          eclient_request_id,
          is_deferred,
          is_trash,
          client_contract_number
        } = item;
        const isRequest = !!(client_division_id && client_division_id !== null && user_id === null)

        const declaration_date = date ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Отсутствует'

        const initials = this.getInitials({first_name, last_name})
        const fullname = this.getFullName({first_name, last_name})

        let status_capitalized = ""
        if(status){
          const [first,...words] = status ? status.split(" ") : []
          status_capitalized = [capitalize(first),...words].join(" ")
        }
        const total = [sum, currency_letter].join(' ')
        const actions = this.getItemActions(item)
        return {
          actions, // меню с действиями
          last_name,
          first_name,
          phone,
          email,
          id,
          declaration_id,
          declaration_type,
          declaration_date,
          reg_num_a,
          reg_num_b,
          client_email,
          client_fio,
          client_phone,
          // client_comment,
          initials,
          user_id,
          client_division_id,
          fullname,
          out_number,
          remark,
          total,
          client_contract_number,
          eclient_request_id,
          is_deferred,
          status:status_capitalized,
          subject_name: isRequest ? "" : (subject_name || subject_person_name),
          counter_agent_name: isRequest ? "" : (counter_agent_name || counter_agent_person_name),
          is_trash
        };
      });
    },
  },
  methods:{
    ...methods, // методы для каждого элемента
    showParentDocument(item){
      const {declaration_id, declaration_type} = item
      if(!declaration_id || !declaration_type) return
      const detailPage = getDetailPageByService(declaration_type)
      const route = this.$router.resolve({
        path: `/${detailPage}/${declaration_id}`
      });
      window.open(route.href ,'_blank')
    },
    getDocumentTypeActions(status){
      const actions = [...this.actions]
      if(status.myRequestDocument){
        const available = ["downloadPdf","downloadPdfDT", "addDocuments"]
        return actions.filter(i => {
          return available.includes(i?.action) || i === null
        })
      }else if(status.isRequest){
        return []
      }else if(status.documentFromRequest){
        const config = {
          downloadPdf:['doc.div.pdf.create'],
          changeRegnum:['doc.div.pdf.create'],
          changeDeferredStatus:['doc.div.pdf.create'],
          downloadDocumentsArhive:['doc.div.pdf.create'],
          copyDocument:['doc.div.copy.create'],
          changePerformer:['doc.priv.user.update'],
          downloadXml:['doc.priv.xml.create'],
          deleteDocument:['doc.priv.delete']
        }
        // Аннулирование и корректировка только для оформленных документов (есть регномер) + оформленных и скорректированных
        if((status.isFramed && !status.isAnnuled)){
          Object.assign(config, {
            adjustDocument:['doc.div.copy.create'],
            annulDocument:['doc.div.copy.create'],
          })
        }
        return this.modifyActions(this.getAvailableActions(config),status)
      }else if(status.myDocument){
        const config = {
          downloadPdf:['doc.div.pdf.create'],
          copyDocument:['doc.div.copy.create'],
          changePerformer:['doc.priv.user.update'],
          downloadXml:['doc.priv.xml.create'],
          deleteDocument:['doc.priv.delete']
        }
        if((status.isFramed && !status.isAnnuled)){
          Object.assign(config, {
            adjustDocument:['doc.div.copy.create'],
            annulDocument:['doc.div.copy.create'],
          })
        }
        return this.modifyActions(this.getAvailableActions(config),status)
      }else if(!status.myDocument){
        const config = {
          downloadPdf:['doc.div.pdf.create'],
          copyDocument:['doc.div.copy.create'],
          changePerformer:['doc.div.user.update'],
          downloadXml:['doc.div.xml.create']
        }
        return this.modifyActions(this.getAvailableActions(config), status)
      }else{
        return []
      }
    },
    modifyActions(actions, status){
      if(!status) return actions;
      return actions.map(i => {
        if(i?.action === "deleteDocument" && status.isTrash){
          return {
            ...i,
            icon:'mdi-delete-off',
            name:'Восстановить'
          }
        }else if(i?.action === "changeDeferredStatus" && status.isDeffered){
          return {
            ...i,
            icon:"mdi-play",
            name:"Возобновить",
          }
        }
        return i
      })
    },
    showDocument(event, {item}) {
      if(this.mobile || // Просмотр только на десктопе
        (this.userId !== null && item.user_id !== this.userId) || // Доступ только к собственным декларациям
        (item.client_division_id && item.user_id === null) // Если заявка
      ) return false;
      const route = this.$router.resolve({
        path: `/${MODULE}/${item.id}`
      });
      window.open(route.href ,'_blank')
    },
  }

}
</script>

<style scoped>
  .isApplication{
    background-color:blue!important;
  }
  .v-data-footer__select{
    display: block;
  }
</style>
